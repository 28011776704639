import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import * as styles from './styles.module.scss';
import Icon from '../Icon';
import Button from '../Button';

type Props = {
  closeModal?: VoidFunction;
  isShown: boolean;
  modalData?: ItemsListType;
  setIsImportant?: (value: boolean) => void;
};

const Modal: React.FC<Props> = ({ isShown = false, closeModal = () => {}, setIsImportant = () => {}, modalData }) => {
  return (
    <Transition appear show={isShown} as={Fragment}>
      <Dialog as="div" className={styles.dialog} onClose={closeModal}>
        <div className={styles.wrapper}>
          <Dialog.Overlay className={styles.overlay} />

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-0"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-500"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-0"
            as={Fragment}
          >
            <div className={styles.modal}>
              <div className={styles.content}>
                <div className={styles.front}>
                  <div className={styles.hexagon}>
                    <div className={styles.hexacontent}>{modalData?.label}</div>
                  </div>
                </div>
                <div className={styles.back}>
                  <div className={styles.hexagon}>
                    <div role="button" aria-hidden onClick={closeModal}>
                      <Icon name="close" className={styles.close} />
                    </div>
                    <div className={styles.hexacontent}>
                      <p
                        className="text-primary"
                        dangerouslySetInnerHTML={{
                          __html: modalData?.title ?? '',
                        }}
                      />
                      <div className={styles.itemsWrapper}>
                        {modalData?.items.map((item: string, index: number) => (
                          <div key={index} className={styles.item}>
                            <Icon name="check" className={styles.icon} width={40} />
                            <p>{item}</p>
                          </div>
                        ))}
                      </div>
                      {modalData?.title2 && (
                        <p
                          className="text-primary"
                          dangerouslySetInnerHTML={{
                            __html: modalData?.title2 ?? '',
                          }}
                        />
                      )}
                      {modalData?.items2 && (
                        <div className={styles.itemsWrapper}>
                          {modalData?.items2.map((item: string, index: number) => (
                            <div key={index} className={styles.item}>
                              <Icon name="check" className={styles.icon} width={40} />
                              <p>{item}</p>
                            </div>
                          ))}
                        </div>
                      )}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: modalData?.description ?? '',
                        }}
                      />
                      <div className={styles.buttonWrapper}>
                        <Button label="Nicht relevant" color="secondary" onClick={() => setIsImportant(false)} />
                        <Button label="Wichtig" color="primary" onClick={() => setIsImportant(true)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
