import React from 'react';
import Icon from '../../components/ui/Icon';
import * as styles from './styles.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.upperWrapper}>
          <p className={styles.text}>
            {
              'Wenn Sie gerne persönlich beraten werden, leistungsstarke Produkte zu fairen Beiträgen gut finden und wenn Sie sich im Schadenfall auf schnelle und unkomplizierte Hilfe verlassen wollen, dann haben Sie mit uns den richtigen Partner an Ihrer Seite. Einen Partner, der sich nicht nur für Sie und die Menschen in Niedersachsen stark macht, sondern auch für Sport, Kultur, Soziales und die Feuerwehren in Ihrer Region. Wenn, dann VGH. Fair versichert.'
            }
          </p>
          <div className={styles.iconWrapper}>
            <a href="https://www.facebook.com/VGHVersicherungen/" target="_blank" rel="noreferrer">
              <Icon name="facebook" width={22} className="text-whiteGray" />
            </a>
            <a href="https://www.instagram.com/vgh_versicherungen/" target="_blank" rel="noreferrer">
              <Icon name="instagram" width={22} className="text-whiteGray" />
            </a>
          </div>
        </div>
        <div className={styles.belowWrapper}>
          <div className={styles.text}>
            <span>© 2022 VGH Versicherungen</span>{' '}
            <a href="https://www.vgh.de/content/impressum/" target="_blank" rel="noreferrer">
              Impressum
            </a>{' '}
            |{' '}
            <a href="https://www.vgh.de/content/datenschutz/" target="_blank" rel="noreferrer">
              Datenschutz
            </a>{' '}
            |{' '}
            <a href="https://www.vgh.de/content/kontakt" target="_blank" rel="noreferrer">
              Kontakt
            </a>{' '}
            {/* | Cookie-Einstellungen */}
          </div>
          <div className={styles.iconWrapper}>
            {/* <Icon name="sparkasse" width={22} className="text-whiteGray" /> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
