import React from 'react';
import HeaderImage from '../../../assets/images/header.svg';

import Headline from '../../ui/Headline';
import * as styles from './styles.module.scss';

const PageHeader = () => (
  <section className={styles.wrapper}>
    <div className={styles.contents}>
      <HeaderImage className="absolute right-0 hidden bottom-30 tablet:block" />
      <div>
        <Headline level={2} color="White">
          Der VGH Rundumschutz für Familien
        </Headline>
        <Headline level={1} color="White" className={styles.headline}>
          Weil Sicherheit das Wichtigste ist
        </Headline>
      </div>
    </div>
  </section>
);

export default PageHeader;
