import React, { useState } from 'react';
import classNames from 'classnames';
import html2pdf from 'html2pdf.js';

import Modal from '../../ui/Modal';
import Icon from '../../ui/Icon';
import Button from '../../ui/Button';
import Headline from '../../ui/Headline';

import * as styles from './styles.module.scss';

const isBrowser = typeof window !== 'undefined';

const ItemsList: ItemsListType[] = [
  {
    id: 1,
    visible: false,
    isText: true,
    label: '',
    title: '',
    items: [],
    description: '',
    selected: 0,
  },
  {
    id: 2,
    visible: true,
    isText: true,
    label: 'CyberSchutz',
    title: 'Der <b>VGH CyberSchutz</b> sichert Sie gegen Internetkriminalität ab.',
    items: [
      'Ersatz für Verluste beim Onlineshopping ',
      'Cyber-Prävention',
      'Löschen missbräuchlich verwendeter persönlicher Daten',
      'Ersatz von Vermögensschäden durch Identitätsmissbrauch, z.B. der Missbrauch von privat genutzten Kredit-, Bank- oder sonstigen Debitkarten beim Bezahlen im Internet',
    ],
    description: '',
    selected: 0,
  },
  {
    id: 3,
    visible: true,
    isText: true,
    label: 'Hausrat- versicherung',
    title:
      'Bei Einbruchdiebstahl-, Leitungswasser- und Feuerschäden etc. schützt die <b>VGH Hausratversicherung</b> Ihr Hab und Gut.',
    items: [
      'grobe Fahrlässigkeit',
      'Überschwemmungen durch Starkregen einschl. Rückstau',
      'Diebstahl aus verschlossenem Auto (inkl. Dachgepäckbox u. Motorradkoffer) bis 3.000 €',
      'Außenversicherung für Sportausrüstungen u. Arbeitsgeräte am Arbeitsplatz bis 6.000 €',
      'Diebstahl Garteninventar (inkl. Kinderspielgeräte) bis 6.000 €',
    ],
    description: '',
    selected: 0,
  },
  {
    id: 4,
    visible: true,
    isText: true,
    label: 'Privathaftpflicht',
    title: 'Die <b>VGH Privathaftpflicht</b> schützt Sie, wenn Sie jemanden aus Versehen schädigen. ',
    items: [
      'Gemietete o. geliehene Sachen',
      'Kinder unter 7 J.: Zahlung trotz mangelnder Haftung',
      'Forderungsausfall: Bleiben Sie nicht auf Ihren Kosten sitzen',
      'Umzugs- und Nachbarschaftshilfe',
      'Mietsachschäden',
      'Im Haushalt lebende Eltern und Großeltern',
    ],
    description: '',
    addition: '',
    selected: 0,
  },
  {
    id: 5,
    visible: false,
    isText: true,
    label: '',
    title: '',
    items: [],
    description: '',
    selected: 0,
  },
  {
    id: 6,
    visible: true,
    isText: true,
    label: 'Fahrrad- versicherung',
    title: 'Die <b>VGH Fahrradversicherung</b> sorgt dafür, dass Ihr hochwertiges Fahrrad vor Schäden geschützt ist.',
    items: [
      'weltweiter Schutz z.B. bei Diebstahl, Unfall, Vandalismus (inkl. Fahrradanhänger)',
      'Schäden an Akku, Motor und Steuerungsgeräten',
      'Verschleißschäden',
      'Pannenhilfe europaweit',
      'Material- und Produktionsfehler',
      'Bedienungsfehler',
    ],
    description: '',
    selected: 0,
  },
  {
    id: 7,
    visible: true,
    isText: true,
    label: 'Betriebliche Altersvorsorge',
    title:
      'Mit der <b>betrieblichen Altersvorsorge</b> der VGH für das Alter vorsorgen, damit es später nicht eng wird.',
    items: [
      'durch Steuer- und ggf. Sozialabgabenersparnis sowie Arbeitgeberzuschuss',
      'auch für Teilzeitbeschäftigte',
      'Alterssicherung über Betrieb',
      'Lebenslange Rentenzahlung oder einmalige Kapitalzahlung',
    ],
    title2: 'Darüber hinaus',
    items2: ['Altersvorsorge auch privat möglich', 'Nachwuchsförderung'],
    description: '',
    selected: 0,
  },
  {
    id: 8,
    visible: true,
    isText: false,
    label: '',
    title: '',
    items: [],
    description: '',
    selected: 0,
  },
  {
    id: 9,
    visible: true,
    isText: true,
    label: 'Tierhalter-haftpflicht',
    title:
      'Die <b>VGH Tierhalterhaftpflicht Hund und Pferd</b> verringert die finanziellen Folgen, falls Ihr Vierbeiner Schäden verursacht.',
    items: [
      'Halter u. Hüter mitversichert (z.B. Familie, Freunde)',
      'Mietsachschäden (z.B. Wohnung, Reitstall)',
      'Teilnahme an Hundeschule, -sport oder Reitturnieren',
      'weltweiter Schutz',
      'Erfüllt die Versicherungspflicht (Hund)',
    ],
    description: '',
    addition: '',
    selected: 0,
  },
  {
    id: 10,
    visible: true,
    isText: true,
    label: 'Risikolebens- versicherung',
    title: 'Die <b>VGH Risikolebensversicherung</b> bietet finanzielle Sicherheit für Ihre Angehörigen im Todesfall.',
    items: [
      'Absicherung von Zahlungsverpflichtungen',
      'Beitragsfreie 20 %-Erhöhung der Versicherungssumme für ein Jahr bei Geburt/Adoption oder Heirat',
      'Plus 20 % der Versicherungssumme bei Unfalltod',
      'Günstige Beiträge für Nichtraucher',
    ],
    description: '',
    addition: '',
    selected: 0,
  },
  {
    id: 11,
    visible: true,
    isText: true,
    label: 'Unfallschutz',
    title: 'Der <b>VGH Unfallschutz</b> hilft Ihnen, die finanziellen Folgen von schweren Verletzungen zu tragen.',
    items: [
      'weltweit & rund um die Uhr',
      'Invaliditätsleistung ab 0,1 % Invalidität',
      'lebenslange Unfall-Rente',
      'verbesserte Gliedertaxe',
      'Das Extra: Ihr persönlicher LeistungslotsePlus',
      'attraktive Sofortleistungen',
      'Impfschäden und Zeckenstiche',
    ],
    description: '',
    addition: '',
    selected: 0,
  },
  {
    id: 12,
    visible: true,
    isText: true,
    label: 'Berufs- unfähigkeits- schutz',
    title:
      'Der <b>VGH Berufsunfähigkeitsschutz</b> sichert Ihr Einkommen, wenn Sie durch Unfall oder Krankheit Ihren Beruf nicht mehr ausüben können.',
    items: [
      'ab 50 % Berufsunfähigkeit',
      'Leistung bei längerer Krankschreibung',
      'Anpassungsrecht ohne erneute Gesundheitsprüfung (bis 45 Jahre) bei Geburt eines Kindes/ Adoption eines Kindes',
      'Günstigerprüfung bei Teilzeitbeschäftigung',
    ],
    description: '',
    addition: '',
    selected: 0,
  },
  {
    id: 13,
    visible: true,
    isText: true,
    label: 'Kfz-Versicherung',
    title: 'Die <b>VGH Kfz-Versicherung</b> bietet für jeden Fahrer den passenden Versicherungsschutz.',
    items: [
      'Autoschutzbrief (schnelle Hilfe europaweit bei Pannen, Unfällen, Diebstahl)',
      '20 % Rabatt für E-Autos',
      'begleitetes Fahren',
    ],
    title2: 'Darüber hinaus für Camper',
    items2: [
      'Camperinhalt mitversichert (außer Inhalt des Vorzeltes)',
      'Festverbundenes Vorzelt und Sportequipment mitversichert',
    ],
    description: '',
    selected: 0,
  },
  {
    id: 14,
    visible: true,
    isText: true,
    label: 'Kranken-Zusatz-versicherungen',
    title: 'Die <b>VGH Kranken-Zusatzversicherungen</b> für eine bessere medizinische Versorgung.',
    items: [
      'Spezialisten-Behandlung im Krankenhaus',
      'Bessere Unterbringung im Krankenhaus',
      'Rooming-In für Kinder bis 14 Jahre',
      'Heilpraktiker',
      'Kieferorthopädie bis zum 18. Lebensjahr',
      'Finanzielle Hilfe im Pflegefall',
    ],
    description: 'Unser Extra: Familienrabatt und Kindernachversicherung',
    selected: 0,
  },
  {
    id: 15,
    visible: true,
    isText: true,
    label: 'Rechtsschutz',
    title: 'Die <b>VGH Rechtsschutzversicherung</b> schützt Sie vor hohen Kosten bei rechtlichen Streitigkeiten. ',
    items: [
      'Absicherung Ihrer Familie inklusive Lebenspartner und Kinder',
      '24/7 telefonische Beratung durch einen Rechtsanwalt',
      'umfassender Privatrechtsschutz (z.B. Kita-Platz einklagen und Darlehensstreitigkeiten bis 15.000 €)',
      'Verkehrsrechtsschutz (z.B. bei einem Verkehrsunfall) ',
      'Berufsrechtsschutz (z.B. bei einer Kündigung)',
    ],
    description: '',
    selected: 0,
  },
];

const StepSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItemsList, setCurrentItemsList] = useState(ItemsList);
  const [modalData, setModalData] = useState<ItemsListType>();
  const [showModal, setShowModal] = useState(false);

  const confirmItem = (idx: number) => {
    setCurrentIndex(idx);
    setModalData({ ...currentItemsList[idx] });
    setShowModal(true);
  };

  const setIsImportant = (val: boolean) => {
    setShowModal(false);
    setCurrentItemsList(prev => {
      prev[currentIndex].selected = val ? 1 : 2;
      return [...prev];
    });
  };

  const generatePDF = () => {
    const element = document.querySelector('#report') as HTMLElement;
    if (isBrowser) {
      html2pdf(element, {
        html2canvas: { scale: 4 },
        filename: `VGH-Ihre-Auswahl.pdf`,
        margin: 12,
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.mobileMessage}>Um die Produkte sehen zu können, benötigst du ein größeres Display.</div>
      <div className={styles.honeycomb}>
        {currentItemsList.map((item, index) => (
          <div
            className={classNames(
              styles.hexagon,
              { invisible: !item.visible },
              { [styles.family]: !item.isText },
              { 'bg-gray': item.selected === 0 && item.isText },
              { [styles.important]: item.selected === 1 },
              { 'bg-lightGray': item.selected === 2 }
            )}
            key={item.id}
            role="button"
            onClick={() => {
              if (item.isText) confirmItem(index);
            }}
            aria-hidden
          >
            <div className={styles.hexagontent}>
              {item.isText ? item.label : <Icon name="family" width={130} height={175} className="mx-auto"></Icon>}
            </div>
            {item.addition && <div className={styles.addition}>{item.addition}</div>}
          </div>
        ))}
      </div>
      <div className={styles.buttonWrapper}>
        <Button label="Auswahl als PDF" className="align" onClick={() => generatePDF()} />
      </div>
      <div id="report" className={styles.report}>
        <div className="flex justify-between">
          <div>
            <span className="text-12">Der VGH Rundumschutz für Familien</span>
            <Headline classLevel={3} className="mt-5">
              <>
                Weil Sicherheit
                <br />
                das Wichtigste ist
              </>
            </Headline>
          </div>
          <Icon name="logoClaim" width={150} className="-mt-20" />
        </div>
        <Headline classLevel={4} className={styles.subline}>
          Ihre Auswahl im Überblick:
        </Headline>
        <div className="grid grid-cols-3 gap-x-20 gap-y-60">
          {currentItemsList
            .filter(item => item.selected === 1)
            .map((item, index) => (
              <div key={index}>
                <div className={styles.hexagon}>
                  <div className={styles.hexagontent}>{item.label}</div>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                  className="mt-20 mb-30 text-12"
                />
                <div className={styles.itemsWrapper}>
                  {item?.items.map((item: string, index: number) => (
                    <div key={index} className={styles.item}>
                      <Icon name="check" className={styles.icon} width={15} height={15} />
                      <span className="text-12 leading-16 inline-block -mt-[3px]">{item}</span>
                    </div>
                  ))}
                </div>
                {item?.title2 && (
                  <p
                    className="mt-20 mb-30 text-12"
                    dangerouslySetInnerHTML={{
                      __html: item?.title2 ?? '',
                    }}
                  />
                )}
                {item?.items2 && (
                  <div className={styles.itemsWrapper}>
                    {item?.items2.map((item: string, index: number) => (
                      <div key={index} className={styles.item}>
                        <Icon name="check" className={styles.icon} width={15} height={15} />
                        <span className="text-12 leading-16 inline-block -mt-[3px]">{item}</span>
                      </div>
                    ))}
                  </div>
                )}
                <p
                  className="text-12 leading-16 pb-20"
                  dangerouslySetInnerHTML={{
                    __html: item?.description ?? '',
                  }}
                />
                {(index === 2 || index === 5 || index === 8 || index === 14) && (
                  <>
                    <div class="html2pdf__page-break"></div>
                    <div className="flex justify-between mt-8">
                      <div></div>
                      <Icon name="logoClaim" width={150} className="-mt-20" />
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
      <Modal
        closeModal={() => setShowModal(false)}
        isShown={showModal}
        modalData={modalData}
        setIsImportant={setIsImportant}
      />
    </section>
  );
};

export default StepSection;
