// extracted by mini-css-extract-plugin
export var addition = "styles-module--addition--hqRkK";
export var buttonWrapper = "styles-module--buttonWrapper--KpJs0";
export var family = "styles-module--family--KThYm";
export var hexagon = "styles-module--hexagon--r9nVH";
export var hexagontent = "styles-module--hexagontent--8e-6E";
export var honeycomb = "styles-module--honeycomb--q0HN7";
export var icon = "styles-module--icon--k6yZ+";
export var important = "styles-module--important--KQXr-";
export var item = "styles-module--item--fhZyb";
export var itemsWrapper = "styles-module--itemsWrapper--58ivK";
export var mobileMessage = "styles-module--mobileMessage---n2YB";
export var report = "styles-module--report--SQNof";
export var subline = "styles-module--subline--Fqql9";
export var wrapper = "styles-module--wrapper--UxNsI";